import './App.less';
import React from 'react';
import { Layout, Button, Carousel, Card, Anchor } from 'antd';
import { WindowsFilled, AppleFilled, AndroidFilled, YoutubeFilled, FireFilled, PlaySquareFilled, BulbFilled } from './icon';

// 轮播数据
const carouselList = [
    {"image": "https://s3.bmp.ovh/imgs/2022/04/03/c0a9ea74671ad9ee.png", title: "最全资源", icon: <FireFilled />},
    {"image": "https://s3.bmp.ovh/imgs/2022/04/03/8826fd9f6cd9f9e2.png", title: "在线观看", icon: <PlaySquareFilled />},
    {"image": "https://s3.bmp.ovh/imgs/2022/04/03/f9be8595d9adfe30.png", title: "追剧提醒", icon: <BulbFilled />},
];

// 平台数据
const platformList = [
    // {"title": "Windows", "icon": <WindowsFilled />, "url": "https://www.jianguoyun.com/p/DWoF64gQ9dS5CRjfzbUEIAA"},
    // {"title": "MacOS", "icon": <AppleFilled />, "url": "https://www.jianguoyun.com/p/DfRpJiQQ9dS5CRjezbUEIAA"},
    // {"title": "Android", "icon": <AndroidFilled />, "url": "https://www.jianguoyun.com/p/DbB4824Q9dS5CRjhzbUEIAA"},
    // {"title": "Android TV", "icon": <YoutubeFilled />, "url": "https://www.jianguoyun.com/p/DfcmKmAQ9dS5CRjgzbUEIAA"},
    {"title": "Windows", "icon": <WindowsFilled />, "url": "https://pan.baidu.com/s/1LorrwZnXdW5aXhOQtdGZgw?pwd=rm56"},
    {"title": "MacOS", "icon": <AppleFilled />, "url": "https://pan.baidu.com/s/1GUlNOJ15hzjF2JkXMJfJyg?pwd=fkq2"},
    {"title": "Android", "icon": <AndroidFilled />, "url": "https://pan.baidu.com/s/1-HL510ee4IvjpC4qYraT-g?pwd=m9ip"},
    {"title": "Android TV", "icon": <YoutubeFilled />, "url": "https://pan.baidu.com/s/1WDueolfOw8KLa8ZA3t8xSA?pwd=d2n3"},
];

class App extends React.Component {
    constructor(){
        super();
        this.state = {
            active: 0, // 正在激活的轮播图
        };
        this.carousel = React.createRef();
        this.setActive = this.setActive.bind(this);
        this.setCarouselActive = this.setCarouselActive.bind(this);
    }
    // 激活轮播图
    setCarouselActive(i){
        this.setState({active: i}, ()=>{
            this.carousel.current.goTo(i, false);
        });
    }
    // 设置激活
    setActive(i){
        this.setState({active: i});
    }
    render(){
        return (
            <Layout className="app">
                <Layout.Header id="index">
                    <div className="logo-wrapper">
                        <img src={require("./static/image/logo.png")} alt="ScumTV" className="logo"/>
                    </div>
                    <Anchor affix={false}>
                        <ul className="menu">
                            <li><Anchor.Link href="#index" title="首页" /></li>
                            <li><Anchor.Link href="#introduction" title="产品介绍" /></li>
                            <li><Anchor.Link href="#download" title="下载" /></li>
                            <li><Anchor.Link href="#contact" title="联系我们" /></li>
                        </ul>
                    </Anchor>
                </Layout.Header>
                <Layout.Content>
                    <div className="introduction" id="introduction">
                        <div className="paragraph-one">极速播放，畅快追剧</div>
                        <div className="paragraph-two">无广告，不收费，资源丰富，点击即播</div>
                    </div>
                    <div className="trial">
                        <Anchor affix={false}>
                            <Button type="primary" size="large"><Anchor.Link href="#download" title="下载试用" /></Button>
                        </Anchor>
                    </div>
                    <div className="support">
                        <div className="text">支持</div>
                        <ul className="platform-list">
                            {
                                platformList.map((item, i)=>{
                                    return (
                                        <li key={i}>{item.icon}{item.title}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="description">
                        <div className="carousel-title">
                            {
                                carouselList.map((item,i)=>{
                                    return (
                                        <div key={i} className="title-container">
                                            <div onClick={()=>{this.setCarouselActive(i)}} className={i === this.state.active ? "title-block active" : "title-block"}>{item.icon} <span>{item.title}</span></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Carousel ref={this.carousel} autoplay dots={false} afterChange={this.setActive}>
                            {
                                carouselList.map((item,i)=>{
                                    return (
                                        <img key={i} src={item.image} alt={item.title} className="carousel-image"/>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                    <div className="download" id="download">
                        <div className="title"><span className="tip">全平台</span>下载</div>
                        <div className="sub-title">适用于Windows, MacOS, 安卓, 安卓电视</div>
                        <div className="platform-list">
                            {
                                platformList.map((item, i)=>{
                                    return (
                                        <div key={i} className="card-container">
                                            <Card
                                                hoverable
                                                onClick={()=>{window.open(item.url)}}
                                            >
                                                {item.icon}
                                            </Card>
                                            <p className="title">{item.title}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="contact" id="contact">
                        <div className="title">联系我们</div>
                        <img src={require("./static/image/wechat.jpeg")} alt="ScumVirus"  className="contact-image"/>
                    </div>
                </Layout.Content>
                <Layout.Footer>
                    Design by ScumVirus
                </Layout.Footer>
            </Layout>
        )
    }
}

export default App;
